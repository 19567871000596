import React, { Component } from "react";
import { Link } from "react-router-dom";
import { allStations } from "./../request/Stations.js";
import StationPicker from "./../Components/StationPicker/";

class PickPage extends Component {
  constructor() {
    super();
    this.state = {
      stations: [],
      fromStation: "",
      toStation: "",
      pickStartStation: true,
      pickEndStation: false,
      seeSummary: false,
    };
  }

  componentDidMount() {
    allStations().then((data) => {
      this.setState({ stations: data });
    });
  }

  onPickedFromStation = (value) => {
    this.setState({ fromStation: value });
  };

  onPickedToStation = (value) => {
    this.setState({ toStation: value });
  };

  onGoToPickEndStation = () => {
    this.setState({ pickStartStation: false, pickEndStation: true });
  };

  onGoToSummary = () => {
    this.setState({ pickEndStation: false, seeSummary: true });
  };

  render() {
    const { fromStation, toStation, stations } = this.state;
    return (
      <main className="pick-page">
        <h1>Välj dina stationer</h1>
        <div className="pick-page-content">
          <label htmlFor="fromStation">Där du bor</label>
          <div name="fromStation" className="station-picker">
            <StationPicker
              stations={stations}
              id="fromStation"
              placeholder={"Hemstation"}
              onPickedStation={this.onPickedFromStation}
            />
          </div>
          <label htmlFor="fromStation">Där du jobbar</label>
          <div name="toStation" className="station-picker">
            <StationPicker
              stations={stations}
              id="toStation"
              placeholder={"Jobbstation"}
              onPickedStation={this.onPickedToStation}
            />
          </div>
          {fromStation && toStation && (
            <Link className="btn" to={`/timetable/${fromStation}/${toStation}`}>
              VISA TIDTABELL
            </Link>
          )}
        </div>
      </main>
    );
  }
}

export default PickPage;
