import { getTimeTable } from "./request.js";

var dateSortAsc = function (date1, date2) {
  // This is a comparison function that will result in dates being sorted in
  // ASCENDING order. As you can see, JavaScript's native comparison operators
  // can be used to compare dates. This was news to me.
  if (date1.sortDate > date2.sortDate) return 1;
  if (date1.sortDate < date2.sortDate) return -1;
  return 0;
};

export function mapDepartures(departures) {
  return departures
    .map((departure) => {
      const isDelayed = !!departure.EstimatedTimeAtLocation;
      const hasDeviations = departure.Deviation && departure.Deviation.length;
      const hasProductInformation =
        departure.ProductInformation && departure.ProductInformation.length;
      return {
        time: departure.AdvertisedTimeAtLocation,
        track: departure.TrackAtLocation,
        deviations: hasDeviations
          ? departure.Deviation.map((deviation) => deviation.Description).join(
              ", "
            )
          : null,
        canceled: departure.Canceled,
        estimatedTime: departure.EstimatedTimeAtLocation,
        extraInfo: hasProductInformation
          ? departure.ProductInformation.map((info) => info.Description).join(
              ", "
            )
          : null,
        isDelayed,
        sortDate: departure.EstimatedTimeAtLocation
          ? departure.EstimatedTimeAtLocation
          : departure.AdvertisedTimeAtLocation,
      };
    })
    .sort(dateSortAsc);
}

export const getDepartures = (fromLocation, toLocation) => {
  const timeTable = getTimeTable(fromLocation, toLocation).then((data) =>
    mapDepartures(data)
  );
  return timeTable;
};
