import React, { Component } from "react";
import "./LoadingSpinner.css";

class LoadingSpinner extends Component {
  render() {
    if (this.props.showSpinner) {
      return (
        <div className="sk-chasing-dots">
          <div className="sk-child sk-dot1" />
          <div className="sk-child sk-dot2" />
        </div>
      );
    }
    return <div className="content">{this.props.children}</div>;
  }
}

export default LoadingSpinner;
