import apiKey from "./apiKey.js";
// import mockResponse from "./tests/mockResponse.js";

const createRequest = (body) => {
  return new Request("https://api.trafikinfo.trafikverket.se/v2/data.json", {
    method: "POST",
    mode: "cors",
    body: body,
    headers: new Headers({
      "Content-Type": "text/xml",
    }),
  });
};

export const getStations = async () => {
  const body = `
    <REQUEST>
      <LOGIN authenticationkey="${apiKey}" />
      <QUERY objecttype="TrainStation" schemaversion="1.4">
        <FILTER>
          <EQ name="CountryCode" value="SE" />
        </FILTER>
      </QUERY>
    </REQUEST>
  `;

  const request = createRequest(body);

  const response = await fetch(request);
  const data = await response.json();
  return data.RESPONSE.RESULT[0].TrainStation;
};

export const getStation = async (signature) => {
  const body = `
    <REQUEST>
      <LOGIN authenticationkey="${apiKey}" />
      <QUERY objecttype="TrainStation" schemaversion="1.4">
        <FILTER>
          <EQ name="LocationSignature" value="${signature}" />
        </FILTER>
      </QUERY>
    </REQUEST>
  `;

  const request = createRequest(body);

  const response = await fetch(request);
  const data = await response.json();
  return data.RESPONSE.RESULT[0].TrainStation;
};

export const getTimeTable = async (fromLocationKey, toLocationKey) => {
  const body = `
    <REQUEST>
      <LOGIN authenticationkey="${apiKey}" />
      <QUERY objecttype="TrainAnnouncement" orderby="AdvertisedTimeAtLocation"  schemaversion="1.6">
        <FILTER>
          <AND>
            <EQ name="ActivityType" value="Avgang" />
            <EQ name="LocationSignature" value="${fromLocationKey}" />
            <IN name="ViaToLocation.LocationName" value="${toLocationKey}" />
            <OR>
              <AND>
                <GT name="AdvertisedTimeAtLocation" value="$dateadd(-00:02:00)" />
                <LT name="AdvertisedTimeAtLocation" value="$dateadd(14:00:00)" />
              </AND>
              <AND>
                <GT name='EstimatedTimeAtLocation' value='$now' />
              </AND>
            </OR>
          </AND>
        </FILTER>
        <INCLUDE>AdvertisedTimeAtLocation</INCLUDE>
        <INCLUDE>EstimatedTimeAtLocation</INCLUDE>
        <INCLUDE>Canceled</INCLUDE>
        <INCLUDE>Deviation</INCLUDE>
        <INCLUDE>TrackAtLocation</INCLUDE>
        <INCLUDE>ToLocation</INCLUDE>
        <INCLUDE>ProductInformation</INCLUDE>
      </QUERY>
    </REQUEST>
  `;

  var request = createRequest(body);

  // return Promise.resolve(mockResponse);
  const response = await fetch(request);
  const data = await response.json();
  return data.RESPONSE.RESULT[0].TrainAnnouncement;
};
