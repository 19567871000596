import React from "react";
import { Link } from "react-router-dom";

function AboutPage() {
  return (
    <div className="AboutPage">
      <div className="header">
        <div className="container">
          <p>
            Var kommer dagspendla ifrån? Jag som har gjort den här sidan heter
            <a
              href="https://stinaq.me/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Stina
            </a>
            , pendlar varje dag och blev trött på att leta efter exakt samma
            avgångar hela tiden. Hoppas du kan ha lika mycket glädje av den som
            jag har.
          </p>
          <p>
            Designen är gjord av{" "}
            <a
              href="https://www.vikipapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Vikki Papp
            </a>
            , designer med bas i Malmö.
          </p>
          <p>
            <Link to={`/`}>Tillbaka till startsidan</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
