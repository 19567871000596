import { getStations, getStation } from "./request.js";

export const allStations = () => {
  return getStations().then(data => {
    return data.map(station => {
      return {
        signature: station.LocationSignature,
        name: station.AdvertisedLocationName
      };
    });
  });
};

export const singleStation = signature => {
  return getStation(signature).then(data => {
    return data.map(station => {
      return {
        signature: station.LocationSignature,
        name: station.AdvertisedLocationName
      };
    })[0];
  });
};
