import React, { Component } from "react";
import TimeTable from "./../Components/TimeTable/";
import LoadingSpinner from "./../Components/LoadingSpinner/";
import { ReactComponent as Refresh } from "../assets/refresh.svg";

class TimeTablePage extends Component {
  render() {
    const {
      trains,
      showSpinner,
      fromStationName,
      toStationName,
      flipToAndFrom,
      reload
    } = this.props;
    return (
      <div className="time-table-page">
        <div className="time-table-header" onClick={flipToAndFrom}>
          <h5 className="from-station">Från {fromStationName}</h5>
          <h3 className="to-station">Till {toStationName}</h3>
        </div>
        <LoadingSpinner showSpinner={showSpinner}>
          <TimeTable trains={trains} />
        </LoadingSpinner>
        <button className="reload-button" onClick={reload}>
          <Refresh />
        </button>
      </div>
    );
  }
}

export default TimeTablePage;
