import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getDepartures } from "./../request/Departures.js";
import { singleStation } from "./../request/Stations.js";
import TimeTablePage from "./TimeTablePage.js";

function TimeTablePageContainer({ isAfternoon }) {
  const params = useParams();
  const [trains, setTrains] = useState([]);
  const [showSpinner, setShowSpinner] = useState(true);
  const [fromStationName, setFromStationName] = useState("");
  const [toStationName, setToStationName] = useState("");
  const [refresh, setRefresh] = useState();
  const [fromLocation, setFromLocation] = useState(
    isAfternoon ? params.toLocation : params.fromLocation
  );
  const [toLocation, setToLocation] = useState(
    isAfternoon ? params.fromLocation : params.toLocation
  );

  const handleFlipToAndFrom = () => {
    const localFromLocation = fromLocation;
    const localToLocation = toLocation;
    setFromLocation(localToLocation);
    setToLocation(localFromLocation);
  };

  const handleRefresh = () => {
    setRefresh(new Date());
  };

  useEffect(() => {
    setShowSpinner(true);
    getDepartures(fromLocation, toLocation).then((data) => {
      setTrains(data);
      setShowSpinner(false);
    });

    singleStation(fromLocation).then((train) => {
      setFromStationName(train.name);
    });

    singleStation(toLocation).then((train) => {
      setToStationName(train.name);
    });
  }, [fromLocation, toLocation, refresh]);

  return (
    <TimeTablePage
      trains={trains}
      showSpinner={showSpinner}
      fromStationName={fromStationName}
      toStationName={toStationName}
      isAfternoon={isAfternoon}
      flipToAndFrom={handleFlipToAndFrom}
      reload={handleRefresh}
    />
  );
}

export default TimeTablePageContainer;
