import React from "react";
import format from "date-fns/format";
import classNames from "classnames";
import { ReactComponent as Danger } from "../../assets/danger.svg";

function Train(props) {
  const train = props.train;
  const isDelayed = train.isDelayed;
  const isCanceled = train.canceled;
  let delayedTime = "";
  if (isDelayed) {
    delayedTime = format(new Date(train.estimatedTime), "HH:mm");
  }
  const originalTime = format(new Date(train.time), "HH:mm");
  const departureTime = isDelayed ? delayedTime : originalTime;
  var trainClasses = classNames({
    train: true,
    "is-delayed": isDelayed,
    "is-canceled": isCanceled,
  });

  return (
    <div className={trainClasses}>
      <div className="time">
        <span className="actual-time">{departureTime}</span>
        {isDelayed && <span className="old-time">{originalTime}</span>}
      </div>
      <div className="train-information">
        {isCanceled && <p className="canceled-headline">INSTÄLLT</p>}
        {!isCanceled && <p className="track">spår {train.track}</p>}
        {isDelayed && (
          <p>
            <i>Föresenat</i>
          </p>
        )}
        {(isDelayed || isCanceled) && (
          <span className="warning">
            <Danger />
          </span>
        )}
        <p>{train.extraInfo}</p>
        {train.deviations && <p>{train.deviations}</p>}
      </div>
    </div>
  );
}

export default Train;
