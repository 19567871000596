import React from "react";
import Train from "../Train";
import { Link } from "react-router-dom";
import { ReactComponent as Refresh } from "../../assets/refresh.svg";

function TimeTable(props) {
  const trains = props.trains;
  const reload = props.reload;

  if (trains.length < 1) {
    return (
      <p>
        Hittade inga avgångar de närmaste två timmarna. Du kan
        <Link to="/"> välja två andra stationer</Link>
      </p>
    );
  }
  return (
    <div className="time-table">
      <button className="reload-button" onClick={reload}>
        <Refresh />
      </button>
      {trains.map((train, index) => {
        return <Train key={index} train={train} />;
      })}
    </div>
  );
}

export default TimeTable;
