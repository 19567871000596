import { isPast } from "date-fns";
import { Routes, Route } from "react-router-dom";
import Layout from "./Pages/Layout";
import StartPage from "./Pages/StartPage";
import PickPage from "./Pages/PickPage";
import AboutPage from "./Pages/AboutPage";
import TimeTablePageContainer from "./Pages/TimeTablePageContainer";

import "./App.css";

function App() {
  const todayMidday = new Date().setHours(12, 0, 0, 0);
  const isAfternoon = isPast(todayMidday);
  if (isAfternoon) {
    document.body.classList.add("afternoon");
  }
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<StartPage />} />
        <Route exact path="pickstations" element={<PickPage />} />
        <Route exact path="about" element={<AboutPage />} />
        <Route
          exact
          path="timetable/:fromLocation/:toLocation"
          element={<TimeTablePageContainer isAfternoon={isAfternoon} />}
        />
      </Route>
    </Routes>
  );
}

export default App;
