import React, { Component } from "react";
import { Link } from "react-router-dom";
import { allStations } from "./../request/Stations.js";

class StartPage extends Component {
  constructor() {
    super();
    this.state = {
      stations: [],
      fromStation: "",
      toStation: "",
      step1: true,
      step2: false,
      step3: false,
      step4: false
    };
  }

  componentDidMount() {
    allStations().then(data => {
      this.setState({ stations: data });
    });
  }

  onPickedFromStation = value => {
    this.setState({ fromStation: value });
  };

  onPickedToStation = value => {
    this.setState({ toStation: value });
  };

  onGoToStep2 = () => {
    this.setState({
      step1: false,
      step2: true
    });
  };

  onGoToStep3 = () => {
    this.setState({
      step2: false,
      step3: true
    });
  };

  onGoToStep4 = () => {
    this.setState({
      step3: false,
      step4: true
    });
  };

  render() {
    return (
      <div className="start-page">
        <main className="start-page-content">
          <h1>Tågpendlar du varje dag?</h1>
          <p>
            Dagspendla kommer ihåg dina stationer och visar bara det du behöver
            veta. Sök en gång aldrig igen!
          </p>
          <div>
            <Link className="btn start-page-button" to={`/pickstations`}>
              VÄLJ STATIONER
            </Link>
          </div>
        </main>
        <footer>
          <p className="start-page-footer">
            <Link to={`/about`}>Om Dagspendla</Link>
          </p>
        </footer>
      </div>
    );
  }
}

export default StartPage;
